import { FlexContainerProps } from './flex-container.props';

export const FlexContainerPresets: FlexContainerProps = {
  direction: 'row',

  justifyContent: 'unset',

  alignItems: 'unset',

  padding: 'unset',

  borderRadius: 'unset',

  height: 'unset',
};
